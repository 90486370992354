<!-- eslint-disable no-unused-vars -->
<script setup>
import { ref, computed, watch } from "vue";
import { useDebounceFn } from "@vueuse/core";
import FhShoppingListCardPresenter from "./FhShoppingListCardPresenter.vue";
import { useShoppingListQuery_V2, useCreateShoppingListMutation, useUpdateShoppingListMutation, useDeleteShoppingListMutation } from "@/api/shoppingListApi";
import FhPaginationNav from "@/components/FhPaginationNav.vue";
import FhModal from "@/components/FhModal.vue";
import FhShoppingListForm from "@/components/FhShoppingListForm.vue";
import { usePaginatedList } from "@/composables";
import scrollToTopOf from "@/util/scrollToTopOf";
import FhButton from "@/components/FhButton.vue";
import FhIcon from "@/components/FhIcon.vue";
import { useNotificationStore } from "@/stores/NotificationStore";
import FhPageHeader from "@/components/FhPageHeader.vue";
import FhLoader from "@/components/FhLoader.vue";
import FhInput from "@/components/FhInput.vue";
import FhFilterSelect from "@/components/FhFilterSelect.vue";

const props = defineProps({
  perPage: {
    type: Number,
    default: 24
  },
  header: {
    type: String,
    default: "Shopping Lists"
  },
  subheader: {
    type: String,
    default: "Saved"
  },
  isAdmin: {
    type: Boolean,
    default: false
  },
  isAdminShoppingList: {
    type: Boolean,
    default: false
  },
  isOwner: {
    type: Boolean,
    default: false
  },
  guestAccounts: {
    type: Array,
    default: () => []
  },
  priceMarkup: {
    type: Object,
    default: () => {}
  }
});

const notificationStore = useNotificationStore();
const showDeleteModal = ref(false);
const showForm = ref(false);
const isEditForm = ref(false);
const shoppingListToModify = ref(null);
const rootElem = ref(null);
const showPopup = ref(false);
const shoppingListToDelete = ref(null);
const searchQuery = ref(null);
const searchTerm = ref(null);
const PER_PAGE = ref(props.perPage);
const pageIndex = ref(0);
const listType = computed(() => (props.isOwner ? "owner" : "guest"));
const selectedGuestAccount = ref(null);
const selectedGuestAccountQuery = ref(null);

const debounceSearch = useDebounceFn(() => {
  searchTerm.value = searchQuery.value;
}, 500);

let {
  isLoading: shoppingListsLoading,
  isFetching,
  isError,
  data: shoppingListResponse
} = useShoppingListQuery_V2(listType.value, pageIndex, selectedGuestAccountQuery, searchTerm);

const shoppingLists = computed(() => {
  if (shoppingListsLoading.value || isError.value) return [];

  return shoppingListResponse.value?.shoppingLists || [];
});

const totalItem = computed(() => shoppingListResponse.value?.shoppingListCount || 0);
const shoppingListPage = computed(() => shoppingListResponse.value?.pageIndex + 1 || 1);

const { isLoading: createMutationLoading, mutate: createShoppingListMutate } = useCreateShoppingListMutation(
  null,
  pageIndex,
  selectedGuestAccountQuery,
  searchTerm
);
const { isLoading: updateMutationLoading, mutate: updateShoppingListMutate } = useUpdateShoppingListMutation(
  null,
  false,
  pageIndex,
  selectedGuestAccountQuery,
  searchTerm
);
const { isLoading: deleteMutationLoading, mutate: deleteShoppingListMutate } = useDeleteShoppingListMutation(
  null,
  false,
  pageIndex.value,
  shoppingListPage.value
);

const guestAccountOptions = computed(() => {
  return props.guestAccounts.map((guestAccount) => ({
    name: guestAccount.name,
    value: guestAccount.id
  }));
});

const markup = ref(props.priceMarkup?.markup);
const showPriceMarkup = ref(props.priceMarkup?.showPriceMarkup || false);
const showCADPricing = ref(props.priceMarkup?.showCADPricing || false);

const isShoppingListDataEmpty = computed(() => totalItem.value === 0);
const isGuestAccount = computed(() => !props.isOwner);

// const isSelectedGuestAccount = computed(() => props.isAdmin && !props.isAdminShoppingList && selectedGuestAccount.value !== null);
// const isSearchQuery = computed(() => searchQuery.value.trim() === "");
const isSearchQuery = computed(() => false);

const shoppingListModalTrigger = (val) => {
  showForm.value = val;
  shoppingListToModify.value = null;
  isEditForm.value = false;
};

const createShoppingList = (shoppingListDetails) => {
  createShoppingListMutate(shoppingListDetails, {
    onSuccess: () => {
      notificationStore.notifySuccess("Your shopping list has been created.");
      pageIndex.value = 0;
    },
    onError: () => notificationStore.notifyError("Sorry, something went wrong and we could not create that shopping List."),
    onSettled: () => {
      shoppingListModalTrigger(false);
      rootElem.value.scrollIntoView(true);
    }
  });
};

const updateShoppingList = (shoppingListDetails) => {
  updateShoppingListMutate(shoppingListDetails, {
    onSuccess: () => notificationStore.notifySuccess("Shopping list updated."),
    onError: () => notificationStore.notifyError("Sorry, something went wrong and we could not update that Shopping list."),
    onSettled: () => {
      shoppingListModalTrigger(false);
      shoppingListToModify.value = null;
      isEditForm.value = false;
      showPopup.value = false;
    }
  });
};

const updateClick = (shoppingListId) => {
  shoppingListToModify.value = shoppingLists.value.find((shoppingList) => shoppingList.id == shoppingListId);
  isEditForm.value = true;
  showForm.value = true;
};

const handleDeleteShoppingList = (shoppingListId) => {
  shoppingListToDelete.value = shoppingListId;
  showDeleteModal.value = true;
};

const closeDeleteModal = () => {
  showDeleteModal.value = false;
  shoppingListToDelete.value = null;
};

const handlePageChange = (page) => {
  scrollToTopOf(rootElem.value);
  pageIndex.value = page - 1;
};

const handleGuestAccountFilter = (selectedGuest) => {
  const guestAccount = guestAccountOptions.value.find((guestAccount) => guestAccount.name == selectedGuest);
  selectedGuestAccountQuery.value = guestAccount?.value || null;
};

watch(searchQuery, debounceSearch);

const removeShoppingList = () => {
  deleteShoppingListMutate(shoppingListToDelete.value, {
    onSuccess: () => {
      notificationStore.notifySuccess("Shopping List removed");
      pageIndex.value = 0;
    },
    onError: () => notificationStore.notifyError("Sorry, something went wrong and we could not remove that shopping list."),
    onSettled: () => closeDeleteModal()
  });
};
</script>

<template>
  <div ref="rootElem">
    <FhPageHeader v-if="!props.isAdmin" :header="props?.header" :subheader="props?.subheader" />
    <div v-if="!props.isAdmin" class="button addButton mt-4 self-center text-right md:mt-0">
      <span class="md:mr-6">{{ totalItem }} Lists </span>
      <hr class="sm:block mb-6 mt-6 opacity-20 md:hidden" />
      <FhButton color="primary" size="medium" class="w-full md:w-auto" @click="shoppingListModalTrigger(true)">
        <FhIcon name="PlusSign" /> Create List
      </FhButton>
    </div>
    <div
      :class="[
        'grid gap-2',
        {
          'grid-cols-1': !props.isAdminShoppingList || props.isAdminShoppingList,
          'md:grid-cols-2': props.isAdminShoppingList && shoppingLists.length > 0,
          'md:grid-cols-1': shoppingLists.length === 0,
          'md:grid-cols-4': !props.isAdminShoppingList,
          'lg:grid-cols-4': !props.isAdminShoppingList
        }
      ]"
    >
      <div class="relative max-w-full">
        <FhInput
          v-model="searchQuery"
          type="text"
          placeholder="Search by Shopping List Name"
          :wrapper-attrs="{ class: 'bg-white relative' }"
          class="border-gray-300 pl-10 md:max-w-md"
        />
        <FhIcon name="Search" class="absolute top-[50%] left-3 -translate-y-1/2 transform text-2xl" style="color: grey" />
      </div>
      <div v-if="props.isAdminShoppingList && !shoppingListsLoading" class="button addButton mt-4 self-center text-right md:mt-0">
        <FhButton v-if="props.isAdminShoppingList" color="primary" size="medium" class="w-full md:w-auto" @click="shoppingListModalTrigger(true)">
          <FhIcon name="PlusSign" /> Create List
        </FhButton>
      </div>
      <div v-if="!props.isAdminShoppingList && props.isAdmin" class="relative max-w-full">
        <FhFilterSelect v-model="selectedGuestAccount" :options="guestAccountOptions" @update:model-value="handleGuestAccountFilter"></FhFilterSelect>
      </div>
    </div>

    <FhLoader v-if="shoppingListsLoading || isFetching" class="my-10" />

    <FhShoppingListCardPresenter
      v-if="!shoppingListsLoading && !isFetching"
      v-model="shoppingLists"
      :has-error="isError"
      :is-empty="isShoppingListDataEmpty"
      :is-admin="props.isAdmin"
      :is-admin-shopping-list="props.isAdminShoppingList"
      :is-guest-account="isGuestAccount"
      :is-selected-guest-account="isSelectedGuestAccount"
      :is-search-query="isSearchQuery"
      @create="createShoppingList"
      @update="updateClick"
      @delete="handleDeleteShoppingList"
    />
    <!-- PAGINATION -->
    <div v-if="totalItem > PER_PAGE" class="mt-f4 md:w-full">
      <FhPaginationNav :page="shoppingListPage" :total-items="totalItem" :per-page="PER_PAGE" @page-change="handlePageChange" />
    </div>
  </div>
  <FhModal :data="{ heading: 'Confirm Shopping List Deletion' }" :is-open="showDeleteModal" @close="closeDeleteModal">
    <template #body>
      <p>This action will permanently delete this shopping list.</p>
    </template>
    <template #footer="{ close }">
      <FhButton color="primary" :disabled="deleteMutationLoading" :is-loading="deleteMutationLoading" @click="removeShoppingList">Delete</FhButton>
      <FhButton variant="text" @click="close">Cancel</FhButton>
    </template>
  </FhModal>
  <FhShoppingListForm
    v-if="showForm"
    :is-open="showForm"
    :is-creating="createMutationLoading || updateMutationLoading"
    :shopping-details="shoppingListToModify"
    :edit-mode="isEditForm"
    :is-admin="props.isAdmin"
    :markup="markup"
    :show-price-markup="showPriceMarkup"
    :show-c-a-d-pricing="showCADPricing"
    :is-admin-shopping-list="props.isAdminShoppingList"
    @create="createShoppingList"
    @update="updateShoppingList"
    @update:is-open="shoppingListModalTrigger"
  />
</template>

<!-- <template>
  <div ref="rootElem">
    <FhPageHeader v-if="!props.isAdmin" :header="props?.header" :subheader="props?.subheader" />
    <div v-if="!props.isAdmin" class="button addButton mt-4 self-center text-right md:mt-0">
      <span class="md:mr-6">{{ totalItem }} Lists </span>
      <hr class="sm:block mb-6 mt-6 opacity-20 md:hidden" />
      <FhButton color="primary" size="medium" class="w-full md:w-auto" @click="shoppingListModalTrigger(true)">
        <FhIcon name="PlusSign" /> Create List
      </FhButton>
    </div>

    <div
      :class="[
        'grid gap-2',
        {
          'grid-cols-1': !props.isAdminShoppingList || props.isAdminShoppingList,
          'md:grid-cols-2': props.isAdminShoppingList && shoppingLists.length > 0,
          'md:grid-cols-1': shoppingLists.length === 0,
          'md:grid-cols-4': !props.isAdminShoppingList,
          'lg:grid-cols-4': !props.isAdminShoppingList
        }
      ]"
    >
      <div v-if="shoppingLists && shoppingLists.length > 0" class="relative max-w-full">
        <FhInput
          v-model="searchQuery"
          type="text"
          placeholder="Search by Shopping List Name"
          :wrapper-attrs="{ class: 'bg-white relative' }"
          class="border-gray-300 pl-10 md:max-w-md"
        />
        <FhIcon name="Search" class="absolute top-[50%] left-3 -translate-y-1/2 transform text-2xl" style="color: grey" />
      </div>
      <div v-if="props.isAdminShoppingList && !isLoading" class="button addButton mt-4 self-center text-right md:mt-0">
        <FhButton v-if="props.isAdminShoppingList" color="primary" size="medium" class="w-full md:w-auto" @click="shoppingListModalTrigger(true)">
          <FhIcon name="PlusSign" /> Create List
        </FhButton>
      </div>
      <div v-if="shoppingLists && shoppingLists.length > 0 && !props.isAdminShoppingList && props.isAdmin" class="relative max-w-full">
        <FhFilterSelect v-model="selectedGuestAccount" :options="guestAccountOptions"></FhFilterSelect>
      </div>
    </div>

    <FhShoppingListCardPresenter
      v-if="!isLoading"
      v-model="shoppingLists"
      :has-error="isError"
      :is-empty="isShoppingListDataEmpty"
      :is-admin="props.isAdmin"
      :is-admin-shopping-list="props.isAdminShoppingList"
      :is-guest-account="isGuestAccount"
      :is-selected-guest-account="isSelectedGuestAccount"
      :is-search-query="isSearchQuery"
      @create="createShoppingList"
      @update="updateClick"
      @delete="handleDeleteShoppingList"
    >
    </FhShoppingListCardPresenter>
  </div>
  <FhLoader v-if="isLoading" class="h-10 w-10" />
  <FhModal :data="{ heading: 'Confirm Shopping List Deletion' }" :is-open="showDeleteModal" @close="closeDeleteModal">
    <template #body>
      <p>This action will permanently delete this shopping list.</p>
    </template>
    <template #footer="{ close }">
      <FhButton color="primary" :disabled="deleteMutationLoading" :is-loading="deleteMutationLoading" @click="removeShoppingList">Delete</FhButton>
      <FhButton variant="text" @click="close">Cancel</FhButton>
    </template>
  </FhModal>
  <FhShoppingListForm
    v-if="showForm"
    :is-open="showForm"
    :is-creating="createMutationLoading || updateMutationLoading"
    :shopping-details="shoppingListToModify"
    :edit-mode="isEditForm"
    :is-admin="props.isAdmin"
    :markup="markup"
    :show-price-markup="showPriceMarkup"
    :show-c-a-d-pricing="showCADPricing"
    :is-admin-shopping-list="props.isAdminShoppingList"
    @create="createShoppingList"
    @update="updateShoppingList"
    @update:is-open="shoppingListModalTrigger"
  ></FhShoppingListForm>
</template> -->
<style lang="pcss" scoped>
:deep(.fhloader) {
  @apply h-12 w-12;
}
</style>
