<!-- eslint-disable no-unused-vars -->
<script setup>
import { ref, computed, reactive } from "vue";
import FhButton from "@/components/FhButton.vue";
import FhModal from "@/components/FhModal.vue";
import FhIcon from "@/components/FhIcon.vue";
import FhLoader from "@/components/FhLoader.vue";
import formatPrice from "../util/formatPrice.js";
import FhInput from "@/components/FhInput.vue";
import FhImage from "@/components/FhImage.vue";
import FhTabs from "@/components/FhTabs.vue";
import FhFilterSelect from "@/components/FhFilterSelect.vue";
import { useShoppingListCondensedWithFlag } from "@/api/shoppingListApi";

const emits = defineEmits(["addtolist", "add:shoppingList", "remove:shoppingList"]);

const props = defineProps({
  shoppinglist: {
    type: Object,
    required: true
  },
  shoppingDetails: {
    type: Object,
    default: () => {}
  },
  isCADPrice: {
    type: Boolean,
    default: false
  },
  isAdmin: {
    type: Boolean,
    default: false
  },
  isCustomerGuest: {
    type: Boolean,
    default: false
  }
});

const formData = reactive({ ...props.shoppingDetails });
const selectedTab = ref(0);
const listType = ref("owner");
const { isLoading, data: shoppingListData } = useShoppingListCondensedWithFlag(formData?.skuNumber, listType);

const shoppingLists = computed(() => (isLoading.value ? [] : shoppingListData.value.shoppingLists));
const guestAccounts = computed(() => (isLoading.value ? [] : shoppingListData.value.guestAccounts));
const searchQuery = ref("");
const selectedGuestAccount = ref(null);

const filteredShoppingLists = computed(() => {
  if (selectedGuestAccount.value) {
    return shoppingLists.value.filter((item) => {
      return item.guestName.toLowerCase().includes(selectedGuestAccount.value.toLowerCase());
    });
  }
  return shoppingLists.value.filter((item) => {
    return item.title.toLowerCase().includes(searchQuery.value.toLowerCase());
  });
});

const guestAccountOptions = computed(() => {
  return guestAccounts.value.map((account) => ({
    name: account.name,
    value: account.name
  }));
});

const toggleHeartIcon = (shoppingList) => {
  if (!shoppingList.hasSku) {
    emits("add:shoppingList", { shoppingList, formData, listType: listType.value });
  } else {
    emits("remove:shoppingList", { shoppingList, formData, listType: listType.value });
  }
};
// const updateCloseContainer = () => {
//   closeContainer.value = !closeContainer.value;
// };
const changeTab = (index) => {
  selectedTab.value = index;
  listType.value = index === 0 ? "owner" : "guest";
};
</script>

<template>
  <FhModal
    ref="modal"
    :data="{
      heading: 'Add to Shopping Lists',
      subheading
    }"
    @close="reset"
  >
    <template #activator="{ open }">
      <slot name="activator" :open="open"></slot>
    </template>
    <template #body>
      <form id="addshoppinglistForm" class="flex flex-col gap-f3" @submit.prevent="save"></form>
      <div class="mb-6 flex items-center gap-3 md:min-w-0 md:gap-f5">
        <a class="block w-32 flex-none border border-neutral-30 md:w-f25">
          <FhImage :src="formData.thumbUrl" :alt="formData.name" square :border="true" />
        </a>
        <div class="flex min-w-0 flex-col gap-1">
          <a class="whitespace-pre-line text-f-base-2xl md:mb-f0.5 md:whitespace-normal">{{ formData.name }}</a>
          <div class="flex gap-f1.5 text-f-xs-base">
            <span v-if="formData.price"
              ><span v-if="(props.isCADPrice && props.isCustomerGuest) || (props.isCADPrice && !props.isAdmin)">CAD</span
              >{{ formatPrice(formData.price) }}</span
            >
          </div>
          <div class="text-f-xs-base text-neutral-50">
            <span v-if="formData.skuName" class="w-15">{{ formData.skuName }}&nbsp;&bull;&nbsp;</span>
            <span v-if="formData.skuNumber">{{ formData.skuNumber }}</span>
          </div>
        </div>
      </div>

      <FhTabs :selected-index="selectedTab" align="left" compact class="w-full" @change="changeTab">
        <template v-if="props.isAdmin" #trigger-MyLists>My Lists</template>
        <template #content-MyLists>
          <div class="filtered-items-container">
            <template v-if="shoppingLists && shoppingLists.length > 0 && !isLoading">
              <div class="mb-6 w-full">
                <FhInput v-model="searchQuery" placeholder="Search" />
              </div>
              <div v-for="item in filteredShoppingLists" :key="item.itemId" class="mb-6 flex gap-3 border-b border-neutral-30 pb-4 md:min-w-0 md:gap-5">
                <a class="block flex-none">
                  <button aria-label="Add to List" class="rounded-full bg-white text-3xl" @click="toggleHeartIcon(item)">
                    <FhIcon :name="item.hasSku ? 'SolidHeart' : 'OutlineHeart'" class="text-gray-500" />
                  </button>
                </a>
                <div class="flex min-w-0 flex-col gap-1">
                  <a class="whitespace-pre-line text-base md:mb-0.5 md:whitespace-normal">{{ item.title }}</a>
                  <div class="flex gap-1.5 text-xs">
                    <span>{{ item.skuCount }} items</span>
                  </div>
                </div>
              </div>
              <p v-if="filteredShoppingLists.length == 0" class="text-body-lg mb-f2 text-neutral-50">There are no Shopping Lists with that name.</p>
            </template>
            <template v-if="shoppingLists.length == 0 && !isLoading">
              <p class="text-body-lg mb-f2 text-neutral-50">You have no Shopping Lists.</p>
            </template>
            <FhLoader v-if="isLoading" class="col-span-2 my-f10" />
          </div>
        </template>
        <template v-if="props.isAdmin" #trigger-GuestLists>Guest Account Lists</template>
        <template v-if="props.isAdmin" #content-GuestLists>
          <template v-if="shoppingLists && shoppingLists.length > 0 && !isLoading">
            <div v-if="guestAccounts && guestAccounts.length > 0" class="mb-6 w-full">
              <FhInput v-model="searchQuery" placeholder="Search by Shopping List name" @click="updateCloseContainer()" />
            </div>
            <div v-if="guestAccounts && guestAccounts.length > 0" class="relative mb-6 w-full">
              <FhFilterSelect v-model="selectedGuestAccount" :options="guestAccountOptions" :close-container="closeContainer"></FhFilterSelect>
            </div>
            <div class="filtered-items-container">
              <div v-if="filteredShoppingLists.length > 0">
                <div v-for="item in filteredShoppingLists" :key="item.itemId" class="mb-6 flex gap-3 border-b border-neutral-30 pb-4 md:min-w-0 md:gap-5">
                  <a class="block flex-none">
                    <button aria-label="Add to List" class="rounded-full bg-white text-3xl" @click="toggleHeartIcon(item)">
                      <FhIcon :name="item.hasSku ? 'SolidHeart' : 'OutlineHeart'" class="text-gray-500" />
                    </button>
                  </a>
                  <div class="flex min-w-0 flex-col gap-1">
                    <a class="whitespace-pre-line text-base md:mb-0.5 md:whitespace-normal">{{ item.title }}</a>
                    <div class="flex gap-1.5 text-xs">
                      <span>{{ item.skuCount }} items by {{ item.guestName }}</span>
                    </div>
                  </div>
                </div>
              </div>
              <p v-if="filteredShoppingLists.length == 0" class="text-body-lg mb-f2 text-neutral-50">
                The selected Guest Account does not have any Shopping Lists.
              </p>
              <!-- <p v-else class="text-body-lg mb-f2 text-neutral-50">There are no Shopping Lists with that name.</p> -->
            </div>
          </template>
          <template v-else>
            <div v-if="!isLoading">
              <p v-if="guestAccounts.length === 0" class="text-body-lg mb-f2 text-neutral-50">Your Guest Accounts do not have any Shopping Lists.</p>
              <p v-else class="text-body-lg mb-f2 text-neutral-50">You have no Guest Accounts or your Guest Accounts do not have any Shopping Lists.</p>
            </div>
          </template>
          <FhLoader v-if="isLoading" class="col-span-2 my-f10" />
        </template>
      </FhTabs>
    </template>
    <template #footer="{ close }">
      <div class="mt-20 grid gap-4" :class="selectedTab === 1 || shoppingLists.length === 0 ? 'grid-cols-1' : 'grid-cols-2'">
        <FhButton
          v-if="selectedTab !== 1"
          type="submit"
          size="medium"
          class="w-full md:w-auto"
          :color="shoppingLists.length === 0 && selectedTab === 0 ? 'primary' : 'secondary'"
          form="addshoppinglistForm"
          @click="emits('addtolist', true)"
        >
          <FhIcon v-if="!(shoppingLists.length === 0 && selectedTab === 0)" name="PlusSign" />{{
            shoppingLists.length == 0 && selectedTab === 0 ? "Create New List" : "Create List"
          }}
        </FhButton>
        <FhButton v-if="!(shoppingLists.length === 0 && selectedTab === 0)" color="primary" class="w-full" @click="close">Continue</FhButton>
      </div>
    </template>
  </FhModal>
</template>

<style scoped>
@media (min-width: 768px) {
  .flex-row-reverse {
    flex-direction: column !important; /* Override with flex-direction: column */
  }
}
.filtered-items-container {
  max-height: 20rem; /* Set a maximum height */
  overflow-y: auto; /* Enable vertical scrollbar when content overflows */
}
</style>
