<script setup>
import { computed, ref } from "vue";
import FhProductFilterBar from "@/components/FhProductFilterBar.vue";
import FhProductGrid from "@/components/FhProductGrid.vue";
import FhAddToShoppingListFormModal from "@/components/FhAddToShoppingListFormModal.vue";
import FhShoppingListForm from "@/components/FhShoppingListForm.vue";
import FhLoader from "@/components/FhLoader.vue";
import { useUpdateItemMutation, useDeleteShoppingItemMutation, useCreateShoppingListMutation } from "@/api/shoppingListApi";
import { event_AddToWishList } from "@/util/googleAnalytics";
import { useNotificationStore } from "@/stores/NotificationStore";

const notificationStore = useNotificationStore();

const { isLoading: addToMutationLoading, mutate: addToShoppingListMutation } = useUpdateItemMutation();
const { isLoading: removeItemToMutationLoading, mutate: removeItemToShoppingListMutation } = useDeleteShoppingItemMutation(null, false, true);
const { isLoading: createShoppingListMutationLoading, mutate: createShoppingListMutation } = useCreateShoppingListMutation();

const props = defineProps({
  products: {
    type: Array,
    default: () => [],
    required: true
  },
  totalProducts: {
    type: Number,
    required: true,
    default: 0,
    validator: (value) => value >= 0
  },
  isLoading: {
    type: Boolean,
    default: false
  },
  filters: {
    type: Array,
    default: () => []
  },
  sortOptions: {
    type: Array,
    default: () => []
  },
  sortOrder: {
    type: String,
    default: "default"
  },
  activeFilters: {
    type: Array,
    default: () => []
  },
  facetCounts: {
    type: Array,
    default: () => []
  },
  hasError: {
    type: Boolean,
    default: false
  },
  isAdmin: {
    type: Boolean,
    default: false
  },
  priceMarkup: {
    type: Object,
    default: () => {}
  },
  isCADPrice: {
    type: Boolean,
    default: false
  },
  isCustomerGuest: {
    type: Boolean,
    default: false
  },
  merchandisingSkus: {
    type: Object,
    default: () => {}
  }
});

const emit = defineEmits(["update:activeFilters", "update:sortValue"]);

const showAddToShoppingListForm = ref(false);
const shoppingListToModify = ref(null);
const showForm = ref(false);

const showResults = computed(() => props.totalProducts > 0);
const showNoResults = computed(() => !props.isLoading & (props.totalProducts == 0) && !props.hasError);
const productsContainer = ref(null);
const markup = ref(props.priceMarkup?.markup || 0);
const showPriceMarkup = ref(props.priceMarkup?.showPriceMarkup || false);
const showCADPricing = ref(props.priceMarkup?.showCADPricing || false);
defineExpose({
  productsContainer
});
function handleaddtoshoppinglist(selectedProduct) {
  shoppingListToModify.value = selectedProduct;
  showAddToShoppingListForm.value = true;
}

const toggleShoppingListFormValue = () => {
  showAddToShoppingListForm.value = false;
};

const addToShoppingListClick = (addToShoppingListData) => {
  const data = {
    skuNumber: addToShoppingListData.formData.skuNumber,
    Quantity: 1,
    ShoppingListId: addToShoppingListData.shoppingList.itemId
  };
  addToShoppingListMutation(
    { data, listType: addToShoppingListData.listType },
    {
      onSuccess: () => {
        notificationStore.notifySuccess("Item added to shopping list.");
        event_AddToWishList({
          ...addToShoppingListData.formData,
          title: addToShoppingListData.shoppingList?.title,
          listId: addToShoppingListData.shoppingList?.itemId
        });
      },
      onError: () => notificationStore.notifyError("Sorry, something went wrong and we could not create that shopping List.")
    }
  );
};
const removeFromShoppingList = (itemToRemoveData) => {
  const product = { ShoppingListID: itemToRemoveData.shoppingList.itemId, id: itemToRemoveData.formData.skuNumber };
  removeItemToShoppingListMutation(
    { ...product, listType: itemToRemoveData.listType },
    {
      onSuccess: () => notificationStore.notifySuccess("Item removed from shopping list."),
      onError: () => notificationStore.notifyError("Sorry, something went wrong and we could not create that shopping List.")
    }
  );
};

function addtolistModal(val) {
  if (val == true) {
    showAddToShoppingListForm.value = false;
    showForm.value = val;
  } else {
    showAddToShoppingListForm.value = true;
    showForm.value = false;
  }
  showForm.value = val;
}
const createShoppingList = (shoppingListDetails) => {
  const shoppinglist = {
    title: shoppingListDetails.title,
    description: shoppingListDetails.description,
    pricingVisibility: shoppingListDetails.pricingVisibility,
    ShoppingListItems: [{ SkuNumber: shoppingListDetails.skuNumber, Quantity: 1 }]
  };
  createShoppingListMutation(shoppinglist, {
    onSuccess: () => notificationStore.notifySuccess("Your shopping list has been created."),
    onError: () => notificationStore.notifyError("Sorry, something went wrong and we could not create that shopping List."),
    onSettled: () => {
      showForm.value = false;
      showAddToShoppingListForm.value = true;
      event_AddToWishList(shoppingListDetails);
    }
  });
};
</script>

<template>
  <FhProductFilterBar
    class="productFilterBar"
    :filters="props.filters"
    :sort-options="props.sortOptions"
    :sort-order="props.sortOrder"
    :total-products-count="props.totalProducts"
    :model-value="props.activeFilters"
    :facet-counts="props.facetCounts"
    @update:model-value="(filtersModel) => emit('update:activeFilters', filtersModel)"
    @update:sort-value="(sortValue) => emit('update:sortValue', sortValue)"
  />

  <div v-if="props.isLoading" class="my-6"><FhLoader class="h-9 w-9" /></div>
  <div v-else ref="productsContainer">
    <FhProductGrid
      v-if="showResults"
      :products="props.products"
      :is-c-a-d-price="props.isCADPrice"
      :is-customer-guest="props.isCustomerGuest"
      :is-admin="props.isAdmin"
      @addtoshoppinglist="handleaddtoshoppinglist"
    />
  </div>

  <!--- Empty Results -->
  <div v-if="showNoResults" class="py-5 text-center text-xl">There are no results</div>

  <!-- Error State-->
  <div v-if="hasError" class="py-5 text-center text-xl">There was an error searching for products</div>

  <FhAddToShoppingListFormModal
    v-if="showAddToShoppingListForm"
    :is-open="showAddToShoppingListForm"
    :shopping-details="shoppingListToModify"
    :is-loading="removeItemToMutationLoading"
    :is-admin="props.isAdmin"
    :is-c-a-d-price="props.isCADPrice"
    :is-customer-guest="props.isCustomerGuest"
    @addtolist="addtolistModal"
    @add:shopping-list="addToShoppingListClick"
    @remove:shopping-list="removeFromShoppingList"
    @update:is-open="toggleShoppingListFormValue"
  ></FhAddToShoppingListFormModal>

  <FhShoppingListForm
    v-if="showForm"
    :is-admin="props.isAdmin"
    :is-open="showForm"
    :is-creating="addToMutationLoading || createShoppingListMutationLoading"
    :shopping-details="shoppingListToModify"
    :edit-mode="false"
    :is-plp-pdp="true"
    :markup="markup"
    :show-price-markup="showPriceMarkup"
    :show-c-a-d-pricing="showCADPricing"
    :is-c-a-d-price="props.isCADPrice"
    :is-customer-guest="props.isCustomerGuest"
    @create="createShoppingList"
    @update:is-open="addtolistModal"
  ></FhShoppingListForm>
</template>
