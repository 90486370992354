import axiosClient from "@/api/axiosClient";
import { useQuery, useMutation, useQueryClient } from "@tanstack/vue-query";

const QUERY_KEY = "shopping_list";
const QUERY_KEY_DETAILS = ["shopping_list_details"];
const QUERY_KEY_CART = ["cart"];

export const useShoppingListQuery_V2 = (list_type = "owner", page = 0, guestUserId = null, searchTerm = null) => {
  return useQuery({
    queryKey: [QUERY_KEY, page, guestUserId, searchTerm],
    queryFn: ({ queryKey }) => {
      return axiosClient.post(`/shoppinglist/${list_type}`, {
        page: queryKey[1],
        searchUserId: queryKey[2] !== null ? queryKey[2].toString() : null,
        searchShoppingListName: queryKey[3]
      });
    },
    select: (shoppingListResponse) => {
      return shoppingListResponse;
    },
    keepPreviousData: true,
    refetchOnWindowFocus: false
  });
};

export const useShoppingListCondensedWithFlag = (skuLookup = "", listType = "owner") => {
  return useQuery({
    queryKey: [QUERY_KEY, listType],
    queryFn: ({ queryKey }) => {
      return axiosClient.get("/shoppinglist/condensed", {
        params: {
          skuLookup,
          listType: queryKey[1]
        }
      });
    }
  });
};

export const useShoppingListQuery = (page, options = null) => {
  return useQuery({
    ...(options || {}),
    queryKey: QUERY_KEY,
    queryFn: () => axiosClient.get("/shoppinglist", { params: { page } }),
    select: (shoppingListResponse) => {
      return shoppingListResponse.shoppingList;
    },
    refetchOnWindowFocus: false
  });
};

//Get to shopping List Detail page
export const useShoppingListDetailQuery = (guid, isPreview, options) => {
  return useQuery({
    ...(options || {}),
    queryKey: QUERY_KEY_DETAILS,
    queryFn: () => axiosClient.get(`/shoppinglist/${guid}/${isPreview}`),
    select: (shoppingListDetailResponse) => shoppingListDetailResponse[0],
    refetchOnWindowFocus: false
  });
};

// Create shopping List
export function useCreateShoppingListMutation(options) {
  const queryClient = useQueryClient();
  return useMutation({
    ...(options || {}),
    mutationFn: ({ id, title, description, pricingVisibility, ShoppingListItems }) =>
      axiosClient.post("/shoppinglist", { id, title, description, pricingVisibility, ShoppingListItems }),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [QUERY_KEY] });
    }
  });
}

//Update Shopping List
// eslint-disable-next-line no-unused-vars
export function useUpdateShoppingListMutation(options, isDetailsPage = false, page = 0, guestUserId = null, searchTerm = null) {
  const queryClient = useQueryClient();
  return useMutation({
    ...(options || {}),
    mutationFn: ({ id, title, description, pricingVisibility }) =>
      axiosClient.post("/shoppinglist", {
        id,
        title,
        description,
        pricingVisibility
      }),
    onSuccess: (updatedShoppingList) => {
      if (updatedShoppingList.length === 0) return;
      if (isDetailsPage) {
        queryClient.setQueryData(QUERY_KEY_DETAILS, () => updatedShoppingList);
      } else {
        queryClient.setQueryData([QUERY_KEY, page, guestUserId, searchTerm], (shoppingListCache) => {
          const updatedList = shoppingListCache.shoppingLists.map((list) => (list.id == updatedShoppingList[0].id ? updatedShoppingList[0] : list));
          return { ...shoppingListCache, shoppingLists: updatedList };
        });
      }
    }
  });
}

//Delete shopping List Mutation
// export function useDeleteShoppingListMutation(options, isDetailPage = false, pageIndex = 0, shoppingListCount = 0) {
//   const queryClient = useQueryClient();
//   return useMutation({
//     ...(options || {}),
//     mutationFn: (id) => axiosClient.delete(`/shoppinglist/${id}`),
//     onSuccess: (_data, deletedId) => {
//       if (isDetailPage) return;
//       const queryKeyFilter = [QUERY_KEY, pageIndex, null, null];
//       queryClient.setQueryData(queryKeyFilter, (cacheResult) => {
//         const updatedShoppingList = cacheResult.shoppingLists.filter((item) => item.id !== deletedId);
//         return { ...cacheResult, shoppingListCount: shoppingListCount - 1, shoppingLists: updatedShoppingList };
//       });
//     }
//   });
// }

export function useDeleteShoppingListMutation(options) {
  const queryClient = useQueryClient();
  return useMutation({
    ...(options || {}),
    mutationFn: (id) => axiosClient.delete(`/shoppinglist/${id}`),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [QUERY_KEY] });
    }
  });
}

// Update the Shopping List Item Quantity
export function useUpdateItemMutation(options, isDetailPage = false) {
  const queryClient = useQueryClient();
  return useMutation({
    ...(options || {}),
    mutationFn: (item) => axiosClient.post("/shoppinglist/item", item.data),
    onSuccess: (updatedShoppingListResponse, context) => {
      if (isDetailPage) {
        // Quick and dirty, will need to update to target specific item as to not cause a full item refresh
        //const { shoppingList: updatedShoppingList } = updatedShoppingListResponse;
        queryClient.setQueryData(QUERY_KEY_DETAILS, updatedShoppingListResponse);
      } else {
        queryClient.setQueryData([QUERY_KEY, context.listType], (shoppingListCache) => {
          const updatedList = shoppingListCache.shoppingLists.map((list) => {
            if (list.itemId == context.data.ShoppingListId) {
              return { ...list, hasSku: true, skuCount: list.skuCount + 1 };
            }
            return list;
          });

          return { ...shoppingListCache, shoppingLists: updatedList };
        });
      }
    }
  });
}

// Delete Item From the shopping List
export function useDeleteShoppingItemMutation(options, isDetailPage = false, isModal = false) {
  const queryClient = useQueryClient();
  return useMutation({
    ...(options || {}),
    mutationFn: (item) => axiosClient.delete(`/shoppinglist/${item.ShoppingListID}/item/${item.id}`),
    onSuccess: (_data, itemToRemoveObject) => {
      if (isDetailPage) {
        queryClient.setQueryData(QUERY_KEY_DETAILS, (shoppingDetailCache) => {
          const shoppingDetail = shoppingDetailCache[0];
          const updatedProducts = shoppingDetail.products.filter((item) => item.id !== itemToRemoveObject.id);
          let totalQuantity = 0;
          let totalPrice = 0;
          updatedProducts.forEach((item) => {
            totalQuantity += item.quantity;
            totalPrice += item.totalPrice;
          });
          return [
            {
              ...shoppingDetail,
              products: updatedProducts,
              totalItems: totalQuantity,
              totalPrice: totalPrice
            }
          ];
        });
      } else if (isModal) {
        const listType = itemToRemoveObject.listType;
        queryClient.setQueryData([QUERY_KEY, listType], (shoppingListsCache) => {
          const updatedShoppingList = shoppingListsCache.shoppingLists.map((shoppingList) => {
            if (shoppingList.itemId == itemToRemoveObject.ShoppingListID) return { ...shoppingList, hasSku: false, skuCount: shoppingList.skuCount - 1 };
            return shoppingList;
          });

          return { ...shoppingListsCache, shoppingLists: updatedShoppingList };
        });
      } else {
        queryClient.setQueryData(QUERY_KEY, (shoppingListsCache) => {
          const updatedList = shoppingListsCache.reduce((accum, currentList) => {
            if (currentList.id == itemToRemoveObject.ShoppingListID) {
              const listRemovedProductArray = currentList.products.filter((product) => product.id != itemToRemoveObject.id);
              const totalItems = Math.max(0, currentList.totalItems - itemToRemoveObject.quantity);
              accum.push({ ...currentList, products: listRemovedProductArray, totalItems });
            } else {
              accum.push(currentList);
            }
            return accum;
          }, []);

          return updatedList;
        });
      }
    }
  });
}

//Add All to cart
export function useAddAllToCartMutation(options) {
  const queryClient = useQueryClient();
  return useMutation({
    ...(options || {}),
    mutationFn: (guid) => axiosClient.post(`/shoppinglist/addalltocart/${guid}`),
    onSuccess: (response) => {
      queryClient.setQueryData(QUERY_KEY_CART, response);
    }
  });
}

// Add to cart mutation
export function useAddToCartMutation(options) {
  const queryClient = useQueryClient();
  return useMutation({
    ...(options || {}),
    mutationFn: (item) => axiosClient.post("/shoppinglist/addtocart", item),
    onSuccess: (data) => {
      queryClient.setQueryData(QUERY_KEY_CART, data);
    }
  });
}
